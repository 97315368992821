<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template v-if="$permissionAbility(ASSET_TYPE_CREATE, permissions)">
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
        :rows="rows"
        :columns="columns"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">

          <template v-if="props.column.field === 'format_name'">
            <b>{{ props.row.name }}</b>
          </template>

          <template v-if="props.column.field === 'format_assets_count'">
            <b-badge class="ml-1" variant="light-primary">
              {{ props.row.assets_count }}
            </b-badge>
          </template>

          <span v-if="props.column.field === 'format_status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status ? "Active" : "Inactive" }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <template
                  v-if="$permissionAbility(ASSET_TYPE_DELETE, permissions)"
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-categories-form"
      centered
      :title="modelCategory == 'editModel' ? 'Edit Type' : 'Create Type'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="leadCategoryValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- name -->
          <b-form-group label="Name" label-for="name" class="required-label">
            <validation-provider
              #default="{ errors }"
              name="name"
              vid="name"
              rules="required|max:255"
            >
              <b-form-input
                id="name"
                type="text"
                v-model="name"
                :state="errors.length > 0 ? false : null"
                placeholder="Category Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- select category type type-->
          <b-form-group label="Type" label-for="type" class="required-label">
            <ValidationProvider
              name="type"
              v-slot="{ errors }"
              vid="type"
              rules="required"
            >
              <v-select
                id="type"
                placeholder="Choose Type"
                v-model="selectType"
                :options="assetAttributeTypeConstants"
                :reduce="(option) => option.value"
                label="name"
                @input="filterTypeWiseAttribute"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
          <!-- select employee type-->
          <b-form-group
            label="Status"
            label-for="status"
            class="required-label"
          >
            <ValidationProvider
              name="status"
              v-slot="{ errors }"
              vid="status"
              rules="required"
            >
              <v-select
                id="status"
                placeholder="Choose Status"
                v-model="selectStatusValue"
                :options="statusValueOption"
                :reduce="(option) => option.value"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
            label="Specification"
            label-for="specification"
            class="required-label"
          >
            <ValidationProvider
              name="specification"
              v-slot="{ errors }"
              vid="specification"
              rules="required"
            >
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                id="specification"
                class="custom-font"
                placeholder="Add Specification"
                v-model="attributeArray"
                :options="filteredAttributeNameOption"
                label="name"
                multiple
                taggable
                push-tags
                @change="filterAttributeNameOption"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isAssetCategoryFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { required } from "@validations";

import { assetAttributeTypeConstants } from "@/helpers/constant/assetAttributeTypeConstant.js";
import {
  ASSET_TYPE_CREATE,
  ASSET_TYPE_EDIT,
  ASSET_TYPE_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "AssetTypeView",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // for attributes
      attributeArray: [],
      filteredAttributeNameOption: [],
      attributeNameOption: [],

      ASSET_TYPE_CREATE,
      ASSET_TYPE_EDIT,
      ASSET_TYPE_DELETE,
      assetCategoryId: "",
      modelCategory: "",
      name: "",
      selectStatusValue: "",
      selectType: "",
      assetAttributeTypeConstants,

      isAssetCategoryFormSubmitLoading: false,
      statusValueOption: [
        {
          name: "Active",
          value: true,
        },
        {
          name: "Inactive",
          value: false,
        },
      ],
      pageLength: 10,
      columns: [
        {
          label: "Name",
          field: "format_name",
          sortable: false,
        },
        {
          label: "Total Asset",
          field: "format_assets_count",
          sortable: false,
        },
        {
          label: "Status",
          field: "format_status",
          formatFn: this.formatFnTableStatus,
          sortable: false,
        },

        {
          label: "Created On",
          field: "created_at",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isUserFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    filterAttributeNameOption(value) {},
    customFilterFunction(query, option) {
      // if (typeof query !== 'string' || !option.name) {
      //   // Handle non-string query or option without a name property
      //   return false;
      // }
      // const nameLower = option.name.toLowerCase();
      // return nameLower.includes(query.toLowerCase()) && !this.attributeArray.includes(option);
    },

    formatFnTableStatus(status) {
      return status ? "Active" : "Inactive";
    },

    showModal() {
      this.$bvModal.show("modal-categories-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-categories-form");
      this.resetModal();
    },

    resetModal() {
      this.modelCategory = "";
      this.name = "";
      this.assetCategoryId = "";
      this.selectStatusValue = "";
      this.attributeArray = [];
      this.selectType = "";
    },

    onShow(value) {
      this.modelCategory = "editModel";
      this.assetCategoryId = value.id;
      this.name = value.name;
      this.selectStatusValue = value.status;
      this.showModal();
    },

    async getAttributes() {
      return await this.$api.get("api/asset-category-attributes");
    },

    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/asset-categories/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Asset Category Deleted Successfully ",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async getAssetCategory() {
      return await this.$api.get("api/asset-categories/all");
    },
    filterTypeWiseAttribute() {
      if (this.selectType) {
        if (this.selectType == 5) {
          this.filteredAttributeNameOption = (
            this.attributeNameOption || []
          ).map((item) => {
            let name = item?.name;
            let type = item?.type;
            let slug = item?.slug;

            return {
              name,
              type,
              slug,
            };
          });
        } else {
          this.filteredAttributeNameOption = (
            this.attributeNameOption || []
          ).filter((item) => item?.type == this.selectType || item?.type == 5);
        }
      } else {
        this.filteredAttributeNameOption = (this.attributeNameOption || []).map(
          (item) => {
            let name = item?.name;
            let type = item?.type;
            let slug = item?.slug;
            return {
              name,
              type,
              slug,
            };
          }
        );
      }
    },
    async loadItems() {
      try {
        const [assetCategories, attributes] = await Promise.all([
          this.getAssetCategory(),
          this.getAttributes(),
        ]);

        const data = assetCategories?.data?.data;
        this.rows = data;

        // attribute
        this.attributeNameOption = attributes?.data?.data;

        this.filteredAttributeNameOption = (this.attributeNameOption || []).map(
          (item) => {
            let name = item?.name;
            let type = item?.type;
            let slug = item?.slug;
            return {
              name,
              type,
              slug,
            };
          }
        );
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.leadCategoryValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelCategory == "editModel") {
              this.isAssetCategoryFormSubmitLoading = true;
              await this.$api.put(
                `/api/asset-categories/${this.assetCategoryId}`,
                {
                  name: this.name,
                  status: this.selectStatusValue,
                }
              );
              this.isAssetCategoryFormSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Asset Category Successfully Updated",
                },
              });
            } else {
              this.isAssetCategoryFormSubmitLoading = true;

              const attributeStrings = this.attributeArray.map(
                (attributeObj) => attributeObj.name
              );

              await this.$api.post("/api/asset-categories", {
                name: this.name,
                status: this.selectStatusValue,
                attribute: attributeStrings,
                type: this.selectType,
              });
              this.isAssetCategoryFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Asset Category Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isAssetCategoryFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.leadCategoryValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>
